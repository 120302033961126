import React from "react";
import "./Contact.css";

const Contact = () => {
  return (
    <section id="section6" className="contact">
      <div className="section-title">Contact Us</div>{" "}
      <div className="contact-details">
        <p>
          <strong>Email Us:</strong>{" "}
          <a href="mailto:nataly@soda-dev.com">marketing@soda-dev.com</a>
        </p>
        <p>
          <strong>Phone Number:</strong>{" "}
          <a href="tel:+972523439599">+972-52-343-9599</a>
        </p>
        <p>
          <strong>Office:</strong> David Center, 1 Carmel Street, Or-Akiva, 3rd
          floor
        </p>
      </div>
      <div className="map-container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3162.799639055007!2d34.91420854966755!3d32.51792932602181!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151d0dc1e3ea115d%3A0xe872a76760d9fd11!2z157XqNeb15Yg15PXldeTINeQ15XXqCDXoten15nXkdeQ!5e1!3m2!1sen!2sil!4v1724269190986!5m2!1sen!2sil"
          width="100%"
          height="300px"
          loading="lazy"
        ></iframe>
      </div>
    </section>
  );
};

export default Contact;

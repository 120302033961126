import React, { useRef, useState, useEffect } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import SlidingBox from "./SlidingBoxes/SlidingBoxes";
import Modal from "./Modal/Modal";
import { projectsData } from "./projectsData";
import "./Projects.css";

interface PopupContent {
  title: string;
  learnMoreText: string;
  imageUrls: string[];
  logos?: string[];
}

const Projects: React.FC = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedPopupContent, setSelectedPopupContent] = useState<
    | {
        title: string;
        learnMoreText: string;
        imageUrls: string[];
        logos?: string[];
      }[]
    | null
  >(null);

  const marqueeRef = useRef<HTMLDivElement>(null);
  const { events } = useDraggable(
    marqueeRef as React.MutableRefObject<HTMLDivElement>
  );

  useEffect(() => {
    const marqueeElement = marqueeRef.current;
    let animationFrameId: number;

    const scroll = () => {
      if (marqueeElement) {
        marqueeElement.scrollLeft += 0.5;
        if (marqueeElement.scrollLeft >= marqueeElement.scrollWidth / 2) {
          marqueeElement.scrollLeft = 0;
        }
      }
      animationFrameId = requestAnimationFrame(scroll);
    };

    animationFrameId = requestAnimationFrame(scroll);

    return () => cancelAnimationFrame(animationFrameId);
  }, []);

  const openModal = (
    projects: {
      title: string;
      learnMoreText: string;
      imageUrls: string[];
      logos?: string[];
    }[]
  ) => {
    const popupContents = projects.map((project) => ({
      title: project.title,
      learnMoreText: project.learnMoreText, // Use `learnMoreText`
      imageUrls: project.imageUrls,
      logos: project.logos,
    }));
    setSelectedPopupContent(popupContents);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedPopupContent(null);
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    const touch = e.touches[0];
    const target = e.currentTarget as HTMLElement;
    target.setAttribute("data-touch-start-x", touch.clientX.toString());
    target.setAttribute("data-touch-start-y", touch.clientY.toString());
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    const touch = e.touches[0];
    const target = e.currentTarget as HTMLElement;
    const startX = parseFloat(target.getAttribute("data-touch-start-x") || "0");
    const startY = parseFloat(target.getAttribute("data-touch-start-y") || "0");
    const deltaX = touch.clientX - startX;
    const deltaY = touch.clientY - startY;

    if (Math.abs(deltaX) > Math.abs(deltaY)) {
      // Horizontal drag detected
      target.scrollLeft -= deltaX;
      e.preventDefault();
    }
  };

  return (
    <section id="section4" className="projects">
      <div className="section-title1">
        Powering Innovation Across Industries
      </div>
      <div
        className="marquee-container"
        {...events}
        ref={marqueeRef}
        style={{
          overflowX: "hidden",
          cursor: "grab",
          display: "flex",
          width: "100%",
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      >
        <div style={{ display: "flex" }}>
          {projectsData.map((project, index) => (
            <div
              key={index}
              className="project-container"
              style={{ minWidth: "350px" }}
            >
              <SlidingBox
                project={project}
                openModal={() =>
                  openModal(project.popupContents || [project.popupContent!])
                }
              />
            </div>
          ))}
          {projectsData.map((project, index) => (
            <div
              key={`duplicate-${index}`}
              className="project-container"
              style={{ minWidth: "350px" }}
            >
              <SlidingBox
                project={project}
                openModal={() =>
                  openModal(project.popupContents || [project.popupContent!])
                }
              />
            </div>
          ))}
        </div>
      </div>

      {selectedPopupContent && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          popupContents={selectedPopupContent}
        />
      )}
    </section>
  );
};

export default Projects;
